<template>
  <v-container fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      title="schedule"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:item.id="{ item }">
          <span class="pa-2 secondary rounded-pill">{{ item.id }}</span>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="12">
            <mission-table
              :schedule_id="item.id"
              :read_only="true"
            ></mission-table>
          </td>
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.type | getText(type_items) }}
        </template>

        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.done_rate="{ item }">{{
          item.mission_done_num + "/" + item.mission_num
        }}</template>
        <template v-slot:item.mission_action="{ item }">
          {{ item.mission_action | getText(spider_actions) | Translate }}
        </template>
        <template v-slot:item.mission_change_type="{ item }">
          {{ item.mission_change_type | getText(change_types) }}
        </template>

        <template v-slot:item.status="{ item }" v-if="isHideExpand">
          {{ item.status | getText(status_items) }}
        </template>

        <template v-slot:item.status="{ item }" v-else>
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(status_items) }}
            <template v-slot:input>
              <!-- <v-text-field v-model="item.status" label="Edit" single-line counter></v-text-field> -->
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="status_items"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <v-tooltip bottom v-if="is_staff">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="createReport(item)"
                >mdi-chart-areaspline</v-icon
              >
            </template>
            <span>{{ $t("create") }}</span>
          </v-tooltip> -->
          <v-tooltip bottom v-if="is_staff">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="verify(item)"
                >mdi-file-chart</v-icon
              >
            </template>
            <span>{{ $t("verify") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { schedule_status, radioOption, change_types } from "@/definition.js";
import CommonUtil from "@/utils/commonutil.js";

export default {
  data: (vm) => ({
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    filterItem: {
      status: -1,
    },
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("customer_mission"),
        value: "customer_mission",
      },
      {
        sortable: true,
        text: vm.$i18n.t("schedule_id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("description"),
        value: "description",
      },
      {
        sortable: false,
        text: vm.$i18n.t("mission_action"),
        value: "mission_action",
      },
      {
        sortable: false,
        text: vm.$i18n.t("mission_change_type"),
        value: "mission_change_type",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: true,
        text: vm.$i18n.t("create_time"),
        value: "create_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("done_rate"),
        value: "done_rate",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "10%",
      },
    ],
    type_items: [
      {
        text: vm.$i18n.t("System"),
        value: 0,
      },
      {
        text: vm.$i18n.t("Customer"),
        value: 1,
      },
    ],
    status_items: schedule_status,
    spider_actions: radioOption,
    change_types: change_types,
    file_upload: false,
    schedule_id: null,
    sortBy: "create_time",
    sortDesc: false,
  }),
  props: { customer_mission_id: null, isHideExpand: false },
  watch: {
    options: {
      handler() {
        this.getSchedule();
      },
    },
  },
  components: {},
  computed: {
    ...mapState(["token", "is_staff"]),
  },
  methods: {
    getSchedule() {
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/schedules/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
          status: 9,
        },
      };

      if (sortBy.length > 0) {
        const sortParas = CommonUtil.getSortParas(sortBy, sortDesc);
        config.params.ordering = sortParas;
      }

      if (!this.$common_util.isEmpty(this.customer_mission_id)) {
        config.params["customer_mission__id"] = this.customer_mission_id;
      }

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log("get scheduld done");
          console.log(response.data);
          currentObj.items = response.data["results"];
          currentObj.totalItems = response.data["count"];
          // console.log(currentObj.items);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },

    updateStatus(item) {
      // patch the status
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/schedules/" + item.id + "/";
      let jsonData = {
        status: item.status,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },

    onClose() {
      this.schedule_id = null;
      this.file_upload = false;
    },
    verify(item) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/schedules/" +
        item.id +
        "/verify/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      const jsonData = {};
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          let count = response.data.count;
          console.log("get count " + count);
          if (count == 0) {
            console.log("create report " + count);
            currentObj.createReport(item);
            alert("report產生中");
          } else {
            alert("有特殊字");
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    createReport(item) {
      console.log(`create repot ${item.id}`);
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/backend/schedules/${item.id}/genreport/`;
      // process.env.VUE_APP_SERVER_URL + "/backend/schedules/" + item.id + "/r";
      let jsonData = {
        status: item.status,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
